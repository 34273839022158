import React from "react";
import styled from "styled-components";
import Logo from "../assets/logo.png";
import Button from "./Button/Button.tsx";
import ShibToken from "../assets/shib-token.png";
import ShibGifContainer from "./ShibGifContainer.tsx";
import Title from "./Title.tsx";
import { ReactComponent as CopySVG } from "../assets/copy.svg";
import { FlexContainer, Header, Section } from "./StyledComponents.tsx";

const Container = styled.main`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 24px;
  width: 100%;
`;

const Card = styled.div`
  padding: 28px;
  width: 100%;
  border-radius: 24px;
  border: 4px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    width: auto;
  }

  & > .title {
    font-size: 24px;
    font-weight: 700;
    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
  & > .description {
    font-size: 16px;
    font-weight: 300;
    text-align: right;
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 480px) {
      font-size: 14px;
      gap: 5px;
    }

    & button {
      background: transparent;
      border: none;
      &:hover {
        transform: scale(1.4);
      }
    }
  }
`;

const CardsContainer = styled(FlexContainer)`
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: unset;
  }
`;

const Address = styled.span`
  @media (max-width: 480px) {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Tokenomics = () => (
  <Section>
    <Container>
      <FlexContainer gap="10px">
        <img src={ShibToken} alt="Logo" width={62} height={62} />
        <Header>Tokenomics</Header>
      </FlexContainer>
      <CardsContainer gap="20px">
        <Card>
          <div className="title">Symbol</div>
          <div className="description">$SHIB</div>
        </Card>
        <Card>
          <div className="title">Tax</div>
          <div className="description">0/0</div>
        </Card>
        <Card>
          <div className="title">LP</div>
          <div className="description">Burned Forever</div>
        </Card>
      </CardsContainer>
      <CardsContainer gap="20px">
        <Card>
          <div className="title">Total Supply</div>
          <div className="description">5,000,000,000</div>
        </Card>
        <Card>
          <div className="title">Ownership</div>
          <div className="description">Renounced</div>
        </Card>
      </CardsContainer>
      <CardsContainer gap="20px">
        <Card>
          <div className="title">Token Address</div>
          <div className="description">
            <Address>5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN</Address>
            <Button
              label="copy contract"
              onClick={() =>
                navigator.clipboard.writeText(
                  "5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN"
                )
              }
            >
              <CopySVG />
            </Button>
          </div>
        </Card>
      </CardsContainer>
    </Container>
  </Section>
);

export default Tokenomics;
