import React from "react";
import styled from "styled-components";
import Rocket from "../assets/rocket.jpg";
import Button from "./Button/Button.tsx";
import { ReactComponent as LinkSVG } from "../assets/link.svg";
import { ReactComponent as CopySVG } from "../assets/copy.svg";
import { FlexContainer, Header, Section } from "./StyledComponents.tsx";
import Logo from "../assets/logo.png";

const Card = styled.div`
  padding: 20px;
  background: rgba(239, 101, 2, 0.864);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  text-align: center;
  gap: 10px;
  color: #fff;

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 10px;
    gap: 5px;
  }

  & h2 {
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
  & img {
    width: 120px;
    height: 90px;
    object-fit: cover;
  }

  & button {
    background: black;

    & svg,
    img {
      width: 20px;
      height: 20px;
      fill: #fff;
    }
  }
`;

const Address = styled.span`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Image = styled.img`
  width: 520px;

  @media (max-width: 768px) {
    width: 320px;
  }

  @media (max-width: 480px) {
    width: 220px;
  }
`;

const HowToBuy = () => (
  <Section>
    <Image src={Rocket} alt="rocket" width={520} />
    <div>
      <FlexContainer gap="10px" style={{ marginBottom: 10 }}>
        <img src={Logo} alt="Logo" width={62} height={62} />
        <Header>
          How to buy <span>$SHIB</span>
        </Header>
      </FlexContainer>
      <Card>
        <h2>Download PHANTOM</h2>
        <p>
          To buy $SHIB, get phantom wallet, Purchase $SOL and send it to your
          Phantom wallet
        </p>
        <Button
          label="get phantom"
          onClick={() => window.open("https://phantom.app/", "_blank")}
        >
          <LinkSVG />
          {/* <img src={Solana} alt="swap" /> */}
          Get Phantom
        </Button>
      </Card>
      <Card>
        <h2>Buy some $SHIB</h2>
        <p>Copy the $SHIB Contract address below</p>
        <Button
          label="copy contract"
          onClick={() =>
            navigator.clipboard.writeText(
              "5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN"
            )
          }
        >
          <CopySVG />
          <Address>5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN</Address>
        </Button>
        <p>Go to Raydium, paste the $SHIB Contract Address to swap your SOL.</p>
        <Button
          label="go to raydium"
          onClick={() =>
            window.open(
              "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN&fixed=in",
              "_blank"
            )
          }
        >
          <LinkSVG />
          Go to Raydium
        </Button>
      </Card>
      <Card>
        <div>
          <h2>HODL to the MOON</h2>
          <p>Now you're all set! Welcome aboard the next rocket to the moon</p>
        </div>
      </Card>
    </div>
  </Section>
);

export default HowToBuy;
