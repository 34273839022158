import React from "react";
import { FlexContainer, Header, Section } from "./StyledComponents.tsx";

const RoadMap = () => (
  <Section>
    <FlexContainer direction="column" gap="20px" $alignItems="flex-start">
      <Header>Road Map</Header>
      <p>
        We are currently working on our roadmap and will be updating it soon.
        Stay tuned for more information on the future of Shiba Inu on Solana.
      </p>
    </FlexContainer>
  </Section>
);

export default RoadMap;
