import React from "react";
import styled from "styled-components";
import Logo from "../assets/logo.png";
import { ReactComponent as TelegramIcon } from "../assets/telegram.svg";
import { FlexContainer } from "./StyledComponents.tsx";
import Button from "./Button/Button.tsx";

const Container = styled.main`
  width: 100%;
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & > .content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: #fff;
    padding: 40px;
    text-align: center;

    @media (max-width: 768px) {
    }

    @media (max-width: 480px) {
      flex-direction: column-reverse;
    }

    & img {
      max-width: 520px;
      width: 100%;
      @media (max-width: 768px) {
        width: 260px;
        height: 260px;
      }
    }

    & .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & h1 {
        font-size: 42px;
        font-weight: 700;

        @media (max-width: 768px) {
          font-size: 32px;
        }
      }

      & h2 {
        font-size: 56px;
        font-weight: 700;
        & span {
          color: rgb(239, 100, 2);
        }

        @media (max-width: 768px) {
          font-size: 42px;
        }
      }

      & p {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
        max-width: 520px;
        width: 100%;
        text-align: center;
        word-wrap: break-word;
      }
    }
  }
`;

const Intro = () => (
  <Container>
    <div className="content-container">
      <div className="content">
        <h1>Welcome to</h1>
        <h2>
          $SHIBA <span>on Solana</span>
        </h2>
        <p>
          Missed Shiba Inu on Ethereum? This is your second chance. Shiba Army
          will rewrite history on Solana.
        </p>
        <FlexContainer gap="20px">
          <Button
            label="buy on raydium"
            onClick={() =>
              window.open(
                "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN&fixed=in",
                "_blank"
              )
            }
            hideLabelOnMobile
          >
            Buy on Raydium
          </Button>
          <Button
            label="telegram"
            onClick={() =>
              window.open("https://t.me/shibsolcommunity", "_blank")
            }
            hideLabelOnMobile
          >
            <TelegramIcon /> <span>Learn More</span>
          </Button>
        </FlexContainer>
      </div>
      <img src={Logo} alt="Logo" />
    </div>
  </Container>
);

export default Intro;
