import "./App.css";
import Header from "./components/Header/Header.tsx";
import Intro from "./components/Intro.tsx";
import StoryOfShibaOnSol from "./components/StoryOfShibaOnSol.tsx";
import MeetShiba from "./components/MeetShiba.tsx";
import HowToBuy from "./components/HowToBuy.tsx";
import Tokenomics from "./components/Tokenomics.tsx";
import Socials from "./components/Socials.tsx";
import RoadMap from "./components/RoadMap.tsx";
import Footer from "./components/Footer.tsx";
import { FlexContainer } from "./components/StyledComponents.tsx";
import Chart from "./components/Chart.tsx";
import Listed from "./components/Listed.tsx";

function App() {
  return (
    <div className="App">
      <Header />
      <FlexContainer direction="column" gap="0px" $alignItems="normal">
        <Intro />
        <Listed />
        <StoryOfShibaOnSol />
        <MeetShiba />
        <HowToBuy />
        <Tokenomics />
        <Chart />
        <RoadMap />
        <Footer />
      </FlexContainer>
    </div>
  );
}

export default App;
