import React from "react";
import styled from "styled-components";

const Video = styled.video`
  width: 520px;
  height: 520px;
  object-fit: cover;
  border-radius: 50%;
  border: 12px solid white;
  pointer-events: none;
  :-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;
  }

  @media (max-width: 768px) {
    width: 320px;
    height: 320px;
  }

  @media (max-width: 480px) {
    width: 220px;
    height: 220px;
  }
`;

interface Props {
  src: string;
}

const ShibGifContainer = ({ src }: Props) => (
  <Video width="320" height="240" autoPlay loop muted controls={false}>
    <source src={src} type="video/mp4" />
  </Video>
);

export default ShibGifContainer;
