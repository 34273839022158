import React from "react";
import styled from "styled-components";
import FooterImage from "../assets/footer-image.png";
import Logo from "../assets/logo.png";
import { FlexContainer } from "./StyledComponents.tsx";
import Button from "./Button/Button.tsx";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as TelegramIcon } from "../assets/telegram.svg";

const StyledFooter = styled.footer`
  border-radius: 80px 80px 0px 0px;
  border-top: 4px solid #fff;
  background: rgb(239, 100, 2);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 64px;

  .container {
    max-width: 1276px;
    padding: 0 42px;
    margin: 0 auto;
    width: 100%;

    z-index: 2;
    padding-top: 42px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 1280px) {
      max-width: 1280px;
    }

    @media (max-width: 1024px) {
      max-width: 1024px;
    }

    @media (max-width: 768px) {
      max-width: 768px;
    }

    @media (max-width: 640px) {
      max-width: 640px;
    }

    @media (max-width: 480px) {
      max-width: 480px;
    }

    .footer__content {
      max-width: 771px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 64px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      line-height: 140%;
      font-weight: 400;

      @media (max-width: 768px) {
        font-size: 14px;
      }
      @media (max-width: 480px) {
        font-size: 14px;
      }

      & .button {
        background: black;
      }

      & .logo {
        width: 150px;

        @media (max-width: 768px) {
          width: 120px;
        }
        @media (max-width: 480px) {
          width: 100px;
        }
      }
    }

    .picture {
      width: 380px;
      position: absolute;
      bottom: 0;
      right: 32px;
      pointer-events: none;

      @media (max-width: 768px) {
        display: none;
      }
    }
    .footer__other {
      width: 100%;
      padding: 16px 0;
      border-top: 2px solid #fff;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 64px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-top: 31px;
    }
  }
`;

const Footer = () => (
  <StyledFooter>
    <div className="container">
      <div className="footer__content">
        {/* <img src={Logo} alt="logo" className="logo" /> */}
        <div>
          <p>
            Shiba Inu on Solana is a decentralized meme token that grew into a
            vibrant ecosystem. We are a community-driven project that is
            dedicated to creating a fun environment for our community and
            building a decentralized ecosystem that will be used for years to
            come.
          </p>
          <FlexContainer gap="20px" style={{ marginTop: "24px" }}>
            <Button
              label="twitter"
              onClick={() =>
                window.open("https://twitter.com/shibainuon_sol", "_blank")
              }
              className="button"
            >
              <TwitterIcon /> Twitter
            </Button>
            <Button
              label="telegram"
              onClick={() =>
                window.open("https://t.me/shibsolcommunity", "_blank")
              }
              className="button"
            >
              <TelegramIcon /> Telegram
            </Button>
          </FlexContainer>
        </div>
      </div>
      <div className="footer__other">
        <div className="">© 2023 Shiba Inu • All Rights Reserved</div>
      </div>
      <img src={FooterImage} alt="footer" className="picture" />
    </div>
  </StyledFooter>
);

export default Footer;
