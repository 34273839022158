import React from "react";
import { FlexContainer, Header, Section } from "./StyledComponents.tsx";
import styled from "styled-components";

const Video = styled.video`
  width: 640px;
  height: 419px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const StoryOfShibaOnSol = () => (
  <Section>
    <Video width="640" height="419" controls>
      <source
        src="https://video.wixstatic.com/video/192f48_156c6f2d41254fa692a5e3fd91b66ec7/1080p/mp4/file.mp4"
        type="video/mp4"
      />
      <source src="movie.ogg" type="video/ogg" />
      Your browser does not support the video tag.
    </Video>

    <FlexContainer direction="column" gap="20px" $alignItems="flex-start">
      <Header>
        STORY OF <br /> Shiba Inu ON SOL
      </Header>
      <p>
        A 100% community-driven token that works around the clock. We don't plan
        to stop until we become a Top 10 crypto. ​
      </p>
      <p>
        After being abandoned by a rogue developer on the first day of launch, a
        group of experienced crypto enthusiasts banded together to create the
        most orchestrated community takeover the industry has ever seen.
      </p>
    </FlexContainer>
  </Section>
);

export default StoryOfShibaOnSol;
