import React from "react";
import styled from "styled-components";
import Logo from "../assets/logo.png";

export const Header = styled.h1`
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  text-align: left;
  & span {
    color: rgb(239, 100, 2);
  }
  @media (max-width: 768px) {
    font-size: 32px;
  }
  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 64px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 48px 40px;
  max-width: 1184px;
  overflow: hidden;
  font-size: 20px;
  text-align: left;
  color: #fff;

  /* @media (max-width: 768px) {
    max-width: 720px;
  } */

  @media (max-width: 480px) {
    flex-direction: column;
    width: auto;
    font-size: 14px;
    padding: 32px 12px;
    gap: 12px;
    margin: 0;
  }
`;

export const FlexContainer = styled.div<{
  direction?: "row" | "column";
  gap?: string;
  isMobileFriendly?: boolean;
  $alignItems?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "baseline"
    | "stretch"
    | "initial"
    | "inherit"
    | "normal";
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  gap: ${({ gap }) => gap || "20px"};
  align-items: ${({ $alignItems }) => $alignItems || "center"};

  @media (max-width: 480px) {
    /* flex-direction: column; */
    /* gap: 12px; */
  }
`;

export const HeaderImage = () => <img src={Logo} alt="logo" />;
