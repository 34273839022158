import React from "react";
import styled from "styled-components";
import Button from "./Button/Button.tsx";
import ShibGifContainer from "./ShibGifContainer.tsx";
import Title from "./Title.tsx";
import CMC from "../assets/coinmarketcap.png";
import Raydium from "../assets/raydium.png";
import { FlexContainer, Header, Section } from "./StyledComponents.tsx";

const Container = styled.main`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 24px;
  width: 100%;
`;

const Card = styled.div`
  padding: 28px;
  width: 100%;
  border-radius: 24px;
  border: 4px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  @media (max-width: 768px) {
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    width: auto;
  }

  & > .title {
    font-size: 24px;
    font-weight: 700;
    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
  & > .description {
    font-size: 16px;
    font-weight: 300;
    text-align: right;
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 480px) {
      font-size: 14px;
      gap: 5px;
    }

    & button {
      background: transparent;
      border: none;
      &:hover {
        transform: scale(1.4);
      }
    }
  }
`;

const CardsContainer = styled(FlexContainer)`
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: unset;
  }
`;

const Address = styled.span`
  @media (max-width: 480px) {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Listed = () => (
  <Section>
    <Container>
      <FlexContainer gap="10px">
        <Header>Listed On</Header>
      </FlexContainer>
      <FlexContainer gap="12px">
        <CardsContainer>
          <a
            href="https://coinmarketcap.com/currencies/shibainu-on-solana/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "white" }}
          >
            <FlexContainer gap="8px">
              <img src={CMC} alt="CoinMarketCap" width={100} height={100} />
              CoinMarketCap
            </FlexContainer>
          </a>
          <a
            href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN&fixed=in"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "white" }}
          >
            <FlexContainer gap="8px">
              <img src={Raydium} alt="Raydium" width={100} height={100} />
              Raydium
            </FlexContainer>
          </a>
          {/* <Card>
            <h2 className="title">CoinGecko</h2>
            <div className="description">
              <CMC />
              <Button
                onClick={() =>
                  window.open(
                    "https://www.coingecko.com/en/coins/shiba-inu",
                    "_blank"
                  )
                }
              >
                View
              </Button>
            </div>
          </Card> */}
        </CardsContainer>
      </FlexContainer>
    </Container>
  </Section>
);

export default Listed;
