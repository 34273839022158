import React from "react";
import styled from "styled-components";
import Logo from "../assets/logo.png";
import Button from "./Button/Button.tsx";
import ShibOnSol from "../assets/shibonsol.jpg";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as TelegramIcon } from "../assets/telegram.svg";
import { ReactComponent as Magnifier } from "../assets/magnifier.svg";
import { FlexContainer, Header, Section } from "./StyledComponents.tsx";

const Image = styled.img`
  width: 520px;
  border: 8px solid white;
  max-width: 640px;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 320px;
  }

  @media (max-width: 480px) {
    width: 220px;
    margin-top: 40px;
  }
`;

const MeetShiba = () => (
  <Section>
    <FlexContainer direction="column" gap="30px" $alignItems="flex-start">
      <FlexContainer gap="10px">
        <img src={Logo} alt="Logo" width={62} height={62} />
        <Header>
          Meet <span>$SHIB</span>
        </Header>
      </FlexContainer>
      <p>
        Shiba represents a digital collectible, allowing users to buy, sell, and
        trade these quirky tokens in a secure and transparent manner. The
        creators behind Shiba have crafted a community-driven project, fostering
        a sense of camaraderie among crypto enthusiasts and meme lovers alike.
        Embrace the spirit of the internet in the decentralized realm with
        Shiba, where laughter and innovation converge in the exciting landscape
        of cryptocurrency."
      </p>
      <FlexContainer gap="20px">
        <Button
          label="twitter"
          onClick={() =>
            window.open("https://twitter.com/shibainuon_sol", "_blank")
          }
          hideLabelOnMobile
        >
          <TwitterIcon /> <span>Twitter</span>
        </Button>
        <Button
          label="telegram"
          onClick={() => window.open("https://t.me/shibsolcommunity", "_blank")}
          hideLabelOnMobile
        >
          <TelegramIcon /> <span>Telegram</span>
        </Button>
        <Button
          label="View on Solscan"
          onClick={() =>
            window.open(
              "https://solscan.io/token/5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN/",
              "_blank"
            )
          }
        >
          <Magnifier />
          View on Solscan
        </Button>
      </FlexContainer>
    </FlexContainer>
    <Image src={ShibOnSol} alt="Shiba on Sol" />
  </Section>
);

export default MeetShiba;
