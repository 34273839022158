import React from "react";
import { Header, Section } from "./StyledComponents.tsx";
import Button from "./Button/Button.tsx";
import styled from "styled-components";

const Container = styled.main`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  gap: 24px;
  width: 100%;

  & > button {
    width: 50%;
  }
`;

const Chart = () => (
  <Section>
    <Container>
      <Header>Chart</Header>
      <div id="dexscreener-embed">
        <iframe
          title="dexscrenner"
          src="https://dexscreener.com/solana/5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN?embed=1&theme=dark&trades=0"
        ></iframe>
      </div>
      <Button
        label="chart"
        onClick={() =>
          window.open(
            "https://dexscreener.com/solana/5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN",
            "_blank"
          )
        }
      >
        View on Dexscreener
      </Button>
    </Container>
  </Section>
);

export default Chart;
