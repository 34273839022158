import React from "react";
import styled from "styled-components";
import Logo from "../../assets/logo.png";
import Button from "../Button/Button.tsx";

const Navbar = styled.nav`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000000;
  max-width: none;
  & button {
    font-size: 16px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  gap: 10px;
  & img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;

const Header = () => (
  <Navbar>
    <LogoContainer>
      <img src={Logo} alt="Logo" />
      <h2>Shiba</h2>
    </LogoContainer>
    <Button
      onClick={() =>
        window.open(
          "https://birdeye.so/token/5MBBsoCVddAuF8XixvCcXNbHAw6WfpZ8WyTKMmczxxRN?chain=solana/#",
          "_blank"
        )
      }
      label="BUY $SHIB"
    >
      BUY $SHIB
    </Button>
  </Navbar>
);

export default Header;
